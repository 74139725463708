import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyCqlUDMjwpC-MaksfNROtAAjDnSHSmXDd0",
  authDomain: "cryocrypt-ab4c2.firebaseapp.com",
  projectId: "cryocrypt-ab4c2",
  storageBucket: "cryocrypt-ab4c2.appspot.com",
  messagingSenderId: "1068931198926",
  appId: "1:1068931198926:web:e87e905d26368e3b7e459f",
  measurementId: "G-3NRDJEBBPX"
};

const App: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("home");

  useEffect(() => {
    // Initialize Firebase inside useEffect
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }, []);

  return (
    <div className="App">
      <div className="background-image"></div>
      <div className="menu">
        <ul>
          <li onClick={() => setActiveTab("home")}>Home</li>
          <li onClick={() => setActiveTab("about")}>About</li>
          <li onClick={() => setActiveTab("tokenomics")}>Tokenomics</li>
          <li onClick={() => setActiveTab("links")}>Links</li>
        </ul>
      </div>

      {activeTab === "home" && (
        <div>
          <br></br>  <br></br>
          <h1>Preparing for a Frozen Eternity</h1>
          <img src="/images/cclogo.png" alt="Token Logo" style={{ width: '800px', margin: '-200px'  }} /><br></br>
          <img src="/images/cctokenlogo.png" alt="CC Token Logo" style={{ width: '160px', margin: '-40px' }} /><br></br>
          <img src="/images/solanalogo.png" alt="Solana Token Logo" style={{ width: '60px', margin: '80px' }} />
        </div>
      )}

      {activeTab === "links" && (
        <div>
          <h1>Preparing for a Link</h1>
        </div>
      )}
    </div>
  );
}

export default App;
